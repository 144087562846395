import Cookies from 'js-cookie';

let boUserName;
let boUserId;

const getBOUserInfos = () => {
    const infos = Cookies.get('CK_BO_USER');
    const match = /^(.+)_([^_]+)$/.exec(infos);

    if (!infos || !match) {
        return null;
    }

    return {
        name: match[1],
        id: match[2]
    };
};

export default function backOfficeInit() {
    const boUserInfos = getBOUserInfos();
    const $siteWrapper = document.querySelector('.site-wrapper');
    const $boBlock = document.getElementById('darty-back-office-info');
    const $name = document.getElementById('darty-back-office-conseiller');
    const $userId = document.getElementById('darty-back-office-user-id');

    if (boUserInfos !== null) {
        if (Object.keys(boUserInfos).length === 0) {
            $boBlock.remove();
        } else {
            boUserName = boUserInfos.name;
            boUserId = boUserInfos.id;
            $name.innerHTML = boUserName;
            $userId.setAttribute('value', boUserId);
            $boBlock.classList.add('active');
            $siteWrapper.classList.add('back-office-active');
        }
    }
}
