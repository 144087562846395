const getBasketDatas = () => {
    const endpoint = '/tunnel_achat/api/cart/header'; // L' URL de l'API

    // On utilise l'API 'fetch' pour récupérer les données du panier
    return fetch(endpoint).then((response) => {
        // On vérifie si la réponse est OK
        if (!response.ok) {
            throw new Error(`La réponse  du réseau n'était pas ok : ${response.statusText}`);
        }
        // On retourne les données au format JSON
        return response.json();
    });
};

export default getBasketDatas;
