let input;
let autocompleteGRS;
const activeClass = 'js-active';

const displayLastSuggestions = () => {
    if (autocompleteGRS.children.length > 0) {
        autocompleteGRS.classList.add(activeClass);
    }
};

const highlightKeyword = (link, term, suggestion) => {
    const regex = new RegExp(term, 'gi');
    const parts = suggestion.split(regex);
    const highlighted = parts.join('<b>' + term + '</b>');
    const linkElement = link;
    linkElement.innerHTML = highlighted;
};

const displaySuggestions = (term, datas) => {
    const list = document.createElement('ul');
    list.classList.add('list');

    const hasAccents = (data) => {
        const firstAccent = new RegExp('[^a-zA-Z0-9_ ]+', 'g');
        return !!data.match(firstAccent);
    };

    const replaceSpaceBy = (terms, separator) => {
        return terms.toLowerCase().replace(/\s/g, separator);
    };

    const hrefy = (data) => {
        const path = '/nav/recherche';

        return hasAccents(data)
            ? `${path}?text=${encodeURIComponent(data)}`
            : `${path}/${replaceSpaceBy(data, '-') + '.html'}`;
    };

    datas.forEach((data) => {
        const item = document.createElement('li');
        const link = document.createElement('a');
        item.classList.add('item');
        link.classList.add('link');
        link.setAttribute('href', hrefy(data));
        item.appendChild(link);
        list.appendChild(item);
        highlightKeyword(link, term, data);
    });

    autocompleteGRS.appendChild(list);
    autocompleteGRS.classList.add(activeClass);
};

const hideSuggestions = () => {
    autocompleteGRS.classList.remove(activeClass);
};

const clearSuggestions = () => {
    autocompleteGRS.innerHTML = '';
    hideSuggestions();
};

async function fetchSuggestions() {
    const url = '/nav/recherche/google?query=' + input.value;
    clearTimeout(input.timeout);

    if (input.value.length >= 2) {
        input.timeout = setTimeout(async () => {
            try {
                const response = await fetch(url);
                const datas = await response.json();
                if (datas.length !== 0) {
                    clearSuggestions();
                    displaySuggestions(input.value, datas);
                } else {
                    clearSuggestions();
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                throw error;
            }
        }, 1000);
    } else {
        clearSuggestions();
    }
}

export default function grsAutocompleteInit() {
    input = document.getElementById('searchbar-input');
    autocompleteGRS = document.getElementById('grs-autocomplete-result-container');

    input.addEventListener('input', fetchSuggestions);
    input.addEventListener('focus', displayLastSuggestions);
    input.closest('.form').addEventListener('submit', (event) => {
        const val = event.target[0].value;
        if (val.length < 2 || val === '' || val === undefined) {
            event.preventDefault();
        }
    });

    document.addEventListener('click', (event) => {
        if (!event.target.closest('.searchbar')) {
            hideSuggestions();
        }
    });
}
