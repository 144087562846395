import Cookies from 'js-cookie';

export default function saveGeoloc(city) {
    const days = 365;
    let domain = '.darty.com';
    const body = document.body;

    if (!body.dataset.isDcom && !body.dataset.isB2b) {
        domain = '.' + location.hostname;
    }

    if (city && city.zipCode && city.insee && city.name) {
        Cookies.set('USERZIPCODE', city.zipCode, { expires: days, domain: domain });
        Cookies.set('USERINSEECODE', city.insee, { expires: days, domain: domain });
        Cookies.set('PCKCITY', city.name, { expires: days, domain: domain });
    }
}
