export default async function isAbTestEnabled(abtest) {
    try {
        const url = `/nav/extra/ajax/abtests?name=${abtest}`;
        const req = await fetch(url);
        const resp = await req.json();
        return resp;
    } catch (error) {
        console.error('Erreur lors de la requête : ', error);
        return false;
    }
}
