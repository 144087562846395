import Cookies from 'js-cookie';

export const userInseeCode = function () {
    return Cookies.get('USERINSEECODE');
};
export const userZipCode = function () {
    return Cookies.get('USERZIPCODE');
};
export const userCity = function () {
    return Cookies.get('PCKCITY');
};
export const isUserLocationValid = function () {
    return userInseeCode() !== undefined && userZipCode() !== undefined;
};
