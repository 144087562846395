const url = '/nav/extra/ajax/headermenu_v3';
const $mainMenu = document.querySelector('.menu');
const $mainTab = document.getElementById('main-tab');
const $universeLinks = document.querySelectorAll('.universe-link');
const $userMenu = document.querySelector('.login-item');
const openClass = 'js-open';

function getParentMenu(id) {
    return $mainTab.querySelector(`li[data-id="${id}"]`);
}

function injectSubMenus(data) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(data, 'text/html');
    doc.querySelectorAll('ul').forEach((menuList) => {
        const menuId = menuList.dataset.id;
        const menuCat = getParentMenu(menuId);

        if (menuCat) {
            menuCat.appendChild(menuList);
        }
    });
    $mainTab.dataset.loaded = true;
}

const loadCompleteMenu = () => {
    fetch(url)
        .then((response) => response.text())
        .then((data) => {
            injectSubMenus(data);
        });
};

const openUniversesList = () => {
    if (!$mainTab.dataset.loaded) loadCompleteMenu();
    $mainTab.classList.add(openClass);
};

const openUniversePanel = (event) => {
    $mainTab.querySelectorAll('.universe').forEach((universe) => {
        universe.classList.remove(openClass);
    });
    $mainTab.classList.add(openClass);
    event.target.closest('.universe').classList.add(openClass);
};

export default function menuInit() {
    const $toggleItem = $mainTab.querySelector('.toggle-item');
    ['mouseenter', 'focus'].forEach((event) => $toggleItem.addEventListener(event, openUniversesList));

    $mainTab.addEventListener('mouseleave', () => {
        $mainTab.classList.remove(openClass);
        $universeLinks.forEach((universeLink) => {
            universeLink.closest('.universe').classList.remove(openClass);
        });
    });

    $universeLinks.forEach((universeLink) => {
        ['mouseenter', 'focus'].forEach((event) => {
            universeLink.addEventListener(event, (e) => {
                openUniversePanel(e);
            });
        });

        universeLink.addEventListener('focusin', () => {
            universeLink.closest('.universe').classList.add(openClass);
        });
    });

    $mainMenu.addEventListener('focusin', () => {
        $mainTab.classList.add(openClass);
    });

    $userMenu.addEventListener('focusin', () => {
        $userMenu.classList.add(openClass);
    });

    $userMenu.addEventListener('focus', () => {
        $userMenu.classList.add(openClass);
    });
}
