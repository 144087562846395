import basketIndicatorInit from '../basket_indicator.js';
import userInformationsInit from '../user_informations.js';
import menusInit from '../header_menus.js';
import geolocInit from '../geoloc.js';
import backOfficeInit from '../darty_back_office.js';
import searchInit from '../search.js';
import devisIndicatorInit from '../devis_indicator.js';

const loadFunction = () => {
    devisIndicatorInit();
    geolocInit();
    backOfficeInit();
};

(() => {
    basketIndicatorInit();
    userInformationsInit();
    searchInit();
    menusInit();

    window.addEventListener('load', loadFunction);
})();
