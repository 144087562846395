// Importation de la fonction getBasketDatas depuis un autre module
import getBasketDatas from './get_basket_datas.js';

// Fonction pour initialiser l'indicateur du panier
const basketIndicatorInit = () => {
    // Sélection de l'élément DOM de l'indicateur de panier par son ID
    const basketIndicator = document.getElementById('js-basket-indicator');

    // Fonction pour vérifier s'il y a des produits dans le panier
    const hasProductInBasket = (basketCount) => basketCount > 0;

    // Fonction pour mettre à jour le nombre de produit dans l'indicateur
    const updateBasketCount = ({ numberOfProducts }) => {
        // Conversion de la chaîne de caractères en nombre
        const nNbProducts = Number(numberOfProducts);

        // Si l'indicateur de panier n'est pas trouvé, arrêter la fonction
        if (!basketIndicator) return;

        // Si des produits sont présents dans le panier, afficher l'indicateur et mettre à jour le nombre
        if (hasProductInBasket(nNbProducts)) {
            basketIndicator.style.display = 'block'; // Affichage de l'indicateur
            basketIndicator.textContent = nNbProducts; // Mise à jour du texte de l'indicateur avec le nombre de produits

            // Si plus de 9 produits, ajouter la classe 'indicator-full', sinon la retirer
            if (nNbProducts > 9) {
                basketIndicator.classList.add('indicator-full');
            } else {
                basketIndicator.classList.remove('indicator-full');
            }
        } else {
            // Si aucun produit, cacher l'indicateur
            basketIndicator.style.display = 'none';
        }
    };

    // Récupération des données du panier et mise à jour de l'indicateur avec ces données
    getBasketDatas().then(updateBasketCount);
};

// Exportation de la fonction pour qu'elle soit utilisable dans d'autres modules
export default basketIndicatorInit;
