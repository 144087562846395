const endpoint = `/espace_client/header/customer-information`;

export default async function getUserInformations() {
    try {
        const response = await fetch(endpoint);
        return await response.json();
    } catch (error) {
        console.error('Erreur lors de la récupération des informations utilisateur:', error);
        throw error;
    }
}
